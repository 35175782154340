function addOnlyClass(parentClass, childrenClass, newClass) {
    var parent = document.querySelector(parentClass);
    var element = parent.querySelector(childrenClass);
    element.classList.add(newClass);  
}

function addMutilClass(parentClass, childrenClass, newClass) {
    var parent = document.querySelector(parentClass);
    var element = parent.querySelector(childrenClass);
    for (var i = 0; i < element.children.length; i++) {
        element.children[i].classList.add(newClass);
    }
}

window.onload = function() {
    var isHome = document.getElementsByTagName("body")[0].classList.contains("home");
    if (isHome) { 
        addOnlyClass(".home-value__slide", ".elementor-widget-container", "splide__track");
        addOnlyClass(".home-value__slide", ".elementor-posts-container", "splide__list");
        addMutilClass(".home-value__slide", ".elementor-posts", "splide__slide");
        new Splide( '.splide', {
            type   : 'loop',
            focus  : 'center',
            autoWidth: true,
            padding: {
                right: '30vw',
                left : '30vw',
            },
        } ).mount();
    }
};
